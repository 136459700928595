@import "src/app/theme/variables";

.container {
  overflow: hidden;
  display: inline-flex;
  align-self: flex-start;

  border-top-left-radius: inherit;
}

.item {
  cursor: pointer;
  padding: $spacing-medium $spacing-large;

  font-weight: 800;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: $font-size-small;

  color: $disabled-text;

  &:not(:last-child) {
    border-right: 1px solid $highlight-color;
  }

  &:hover {
    color: $text-color;
    background: $highlight-color;
  }

  &[data-active=true] {
    color: $primary-color;
    background: $highlight-color;
  }

  @include media-max($breakpoint-small) {
    padding: $spacing-normal $spacing-medium;
  }
}
