.container {
  display: flex;
  flex-direction: column;
}

.gapSm {
  gap: 8px;
}
.gapMd {
  gap: 16px;
}
.gapLg {
  gap: 32px;
}

.horizontal {
  flex-direction: row;
}

.fill {
  > * {
    flex: 1;
  }
}
