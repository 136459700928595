@import "src/app/theme/variables";

.container {
  display: flex;
  align-items: center;
  gap: $spacing-normal;

  height: $header-size;
  padding: 0 $spacing-normal;

  background: $body-color;
  border-bottom: 1px solid $border-color;
  color: $title-text-color;
}

.flexSpacer {
  flex: 1;
}
