@import "src/app/theme/variables";

.section {
  display: flex;
  flex-direction: column;
  gap: $spacing-extra-small;

  .label {
    display: flex;
    align-items: center;
    gap: $spacing-extra-small;

    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: $font-size-small;
    padding-left: $spacing-medium;

    color: $disabled-text;

    .icon {
      font-size: 1.5em;
    }

    @include media-max($menu-breakpoint) {
      display: none;
    }
  }

  .items {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
