@import "src/app/theme/variables";

.tabbedCard {
  gap: 0;
  padding: 0;
}

.tabContent {
  display: flex;
  gap: $spacing-medium;
  padding: $spacing-medium;
  flex-direction: column;
}
