.large {
  :global(.react-datepicker__day-name),
  :global(.react-datepicker__day),
  :global(.react-datepicker__time-name) {
    width: 3rem;
    line-height: 3rem;
  }

  @media (max-width: 400px), (max-height: 550px) {
    :global(.react-datepicker__day-name),
    :global(.react-datepicker__day),
    :global(.react-datepicker__time-name) {
      width: 2rem;
      line-height: 2rem;
    }
  }

  :global(.react-datepicker__current-month),
  :global(.react-datepicker-time__header) {
    font-size: 1.44rem;
  }
}
