@import "src/app/theme/variables";

.container {
  gap: 0;
  display: flex;
  flex-direction: column;

  color: inherit;
  min-width: 170px;

  &.primary {
    color: $text-color-on-primary;
    background-color: $primary-color;
  }
}

.titleText {
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: $font-size-small;
}

.contentText {
  font-weight: 900;
  font-size: $font-size-large;
}
