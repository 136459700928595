@import "src/app/theme/variables.scss";

* {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  display: flex;

  font-size: $font-size-base;
  font-family: $main-font-family;

  color: $text-color;
  background: $body-color;

  width: 100%;
  height: 100%;

  overscroll-behavior-y: none;
  transition: background 0.2s ease-in, color 0.2s ease-in;
}

#root {
  flex: 1;
  display: flex;
}

h1, h2, h3, h4, h5, h6 {
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
  color: $title-text-color;
}

a, a:hover, a:active, a:visited {
  color: inherit;
  text-decoration: none;
}

pre {
  margin: 0;
}
