@import "src/app/theme/variables";

.container {
  display: flex;
  flex-wrap: wrap;
  gap: $spacing-extra-small;
}

.label {
  font-weight: 500;
  font-size: $font-size-small;

  border: 1px solid currentColor;
  border-radius: $radius-extra-small;
  padding: ($spacing-extra-small * 0.75) $spacing-extra-small;

  cursor: pointer;

  &[data-active=true] {
    color: $secondary-color;
    border-color: $secondary-color;
  }

  &[data-disabled=true] {
    cursor: not-allowed;

    &:not(.active) {
      color: $disabled-text;
    }
  }
}

.input {
  // This hides the original ui while trying to preserve accessibility
  position: fixed;
  opacity: 0;
  pointer-events: none;
}
