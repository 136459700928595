@import "src/app/theme/variables";

.container {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: $spacing-large;

  font-size: $font-size-medium;
}
