@import "src/app/theme/variables";

.container {
  // TODO: move to <DatePicker> CSS?
  :global(.react-datepicker__children-container) {
    margin: 0;
    padding: 0;
  }
}

.mainButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-extra-small;

  padding: $spacing-extra-small;

  background: transparent;
  border: 1px solid $border-color;
  border-radius: $radius-extra-small;
  color: $primary-color;
  font-size: $font-size-extra-small;
  font-weight: bold;
  letter-spacing: 1px;
  outline: none;
  text-align: left;
  text-transform: uppercase;

  &:disabled {
    color: $disabled-input-color;
  }

  &:not(:disabled) {
    &:hover {
      border-color: $highlight-color;
    }
    &:focus {
      border-color: $primary-color;
    }
  }
}

.relativeRangesContainer {
  display: grid;
  padding-top: $spacing-small;
  gap: $spacing-extra-small;
  grid-template-columns: repeat(3, 1fr);
}
