@import "src/app/theme/variables";

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  --loader-size: 160px;

  &[data-inline="true"] {
    flex: none;
    aspect-ratio: 1/1;
    position: relative;
    width: var(--loader-size);
  }

  &[data-center="true"] {
    align-self: center;
  }
}

.ripple {
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--loader-size)px;
  height: var(--loader-size)px;
  aspect-ratio: 1/1;
}

.ripple div {
  width: 0;
  opacity: 1;
  aspect-ratio: 1/1;
  position: absolute;
  border-radius: 50%;
  border: 4px solid $primary-color;

  animation: ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple div:nth-child(2) {
  animation-delay: -1s;
}

@keyframes ripple {
  0% { opacity: 0 }
  4.9% { opacity: 0 }
  5% { opacity: 1 }
  100% {
    width: 160px;
    opacity: 0;
  }
}
