@import "src/app/theme/variables";

.card {
  display: flex;
  gap: $spacing-medium;
  padding: $spacing-medium;
  flex-direction: column;

  position: relative;
  background: $container-color;

  border-radius: $radius-small;

}

.card[data-border="true"] {
  border: 1px solid $border-color;
}

.card[data-shadow="true"] {
  @include card-shadow;
}
