@import "src/app/theme/variables";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  padding-block: $spacing-small;
  gap: $spacing-medium;
}
