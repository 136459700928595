@import "src/app/theme/variables";

.container {
  display: flex;
  gap: $spacing-small;

  > * {
    flex: 1;
  }
}
