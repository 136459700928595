.icon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;

  line-height: 1;

  color: inherit;
  font-size: inherit;

  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga';

  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
}
