@import "src/app/theme/variables";

.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  gap: $spacing-normal;
  padding: $spacing-small $spacing-normal;

  background: $highlight-color;

  .text {}

  a, a:hover, a:active, a:visited {
    font-weight: 600;
    color: $secondary-color;
    font-size: $font-size-small;

    text-decoration: none;
    text-transform: uppercase;
  }
}
