@import "src/app/theme/variables";

.container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  text-align: center;
  letter-spacing: 1px;
  color: $disabled-color;
  font-size: $font-size-small;

  border-radius: $radius-extra-small;
  border: 1px dashed $disabled-color;

  .icon {}
}
