@import "src/app/theme/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: $spacing-extra-small;
}

.title {
  color: $title-text-color;
}

.subtitle {
  color: inherit;
}
