@import "src/app/theme/variables";

.container {
  width: 30em;
  display: flex;
  flex-direction: column;

  gap: $spacing-small;
  word-break: normal; /* TODO: remove the setting from <Modal>? */
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: $spacing-small;

  strong {
    color: transparentize($secondary-color, 0.2);
  }

  p {
    margin: 0;
  }
}
