@import "src/app/theme/variables";

.container {
  display: flex;
  gap: $spacing-small;

  @include media-max($breakpoint-medium) {
    gap: $spacing-extra-small;
  }

  @include media-max($breakpoint-small) {
    flex-direction: column;
  }

  > * {
    flex: 1;
  }
}
