@import "src/app/theme/variables";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.headerContainer {
  top: 0;
  z-index: 10;
  position: sticky;
}

.pageContent {
  flex: 1;
  display: flex;
}

.sidebar {
  width: 300px;
  display: flex;
  flex-direction: column;

  top: 0;
  position: sticky;
  height: 100dvh;

  background: $container-color;

  .logoContainer {
    display: flex;
    align-items: center;
    height: $header-size;
    padding-inline: $spacing-medium;
    border-bottom: 1px solid $border-color;

    @include media-max($menu-breakpoint) {
      padding-inline: $spacing-normal;
    }
  }

  .menuAndUser {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow-y: auto;

    .userSection {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: $spacing-small;
      gap: $spacing-extra-small;
      padding-block: $spacing-normal;

      text-align: center;

      @include media-max($menu-breakpoint) {
        display: none;
      }
    }
  }

  @include media-max($menu-breakpoint) {
    width: 90px;
  }
}

.sectionContent {
  flex: 1;
}

.suspenseLoaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-block: $spacing-large;
}

:export {
  sidebar-breakpoint: $menu-breakpoint;
}
