@import "src/app/theme/variables";

$logo-width: 150px;

.logo {
  width: var(--logo-width, #{$logo-width});

  @include media-max($menu-breakpoint) {
    width: 100%;
    height: auto;
  }
}
