@import "src/app/theme/variables";

.input {
  padding: $spacing-extra-small;
  border-radius: $radius-extra-small;

  outline: none;
  height: $input-height;

  background: transparent;
  color: $title-text-color;
  border: 1px solid $border-color;

  &:hover:not(:disabled) {
    border-color: $highlight-color;
  }

  &:focus:not(:disabled) {
    border-color: $primary-color;
  }

  &[data-error=true] {
    border-color: $error-color;
  }

  &[data-size="small"] {
    height: auto;
    font-size: $font-size-small;
  }

  &:disabled, &:read-only {
    color: $disabled-input-color;

    ::placeholder {
      opacity: 0.5;
    }
  }
}
