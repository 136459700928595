/** Neutral **/
$white: #fff;

/** General **/
$error-color: #ff6e6e;
$success-color: #388e3c; // was #007734, but that was too dark
$warning-color: #ffc04f;

$disabled-color: #4f4e4e;
$disabled-text: transparentize(#919fb6, 0.3);

$table-row-text: #bebebe;

/** Brand **/
$primary-color: #4bcfcc;
$text-color-on-primary: #111315;

$secondary-color: #e1a200;
$text-on-secondary: #111315;

/** Theme **/
$text-color: #e3e3e3;
$secondary-text: #9e9fa9;
$title-text-color: #e7e9ea;

$body-color: #111315;
$accent-color: #0c1015;
$container-color: #1a1d1e;

$border-color: #222425;
$disabled-color: #6c6c6c;
$highlight-color: #272b30;

$shadow-color: #111111;
$overlay-color: rgba(70, 70, 70, 0.5);
