@import "src/app/theme/variables";

.container {
  align-self: flex-start;
  background: $highlight-color;
  border-radius: $radius-extra-small;
  gap: $spacing-small;
  padding: $spacing-extra-small $spacing-small;
}

.item {
  border: none;
  color: $disabled-text;
  font-size: $font-size-small;
  padding: 0;

  &:hover {
    color: $text-color;
  }

  &[data-active=true] {
    color: transparentize($primary-color, 0.3);
  }
}
