@import "src/app/theme/variables";

.button {
  background: transparent;
  border: 1px solid transparentize($color: $disabled-color, $amount: 0.3);
  border-radius: $radius-extra-small;
  color: $text-color;
  cursor: pointer;
  opacity: 0.4;
  padding: $spacing-extra-small;
  text-align: left;

  &:hover {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $text-color-on-primary;
    opacity: 0.6;
  }
}
