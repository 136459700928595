@import "src/app/theme/variables";

.sidebarLink {
  overflow: hidden;
  position: relative;

  flex: 1;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: $spacing-extra-small;
  padding: $spacing-small $spacing-medium;

  font-weight: 600;
  letter-spacing: 1px;
  font-size: $font-size-small;

  border: 1px solid transparent;

  &.sidebarLinkActive {
    color: $primary-color;
  }

  &:hover:not(.sidebarLinkActive):not([aria-disabled=true]) {
    color: $title-text-color;
    background: $highlight-color;
  }

  &[aria-disabled=true] {
    color: $disabled-text;
  }

  @include media-max($menu-breakpoint) {
    justify-content: center;
    padding-inline: 0;
  }
}

.icon {
  color: inherit;
  font-size: 1.3em;

  @include media-max($menu-breakpoint) {
    font-size: 2em;
  }
}

.label {
  @include media-max($menu-breakpoint) {
    display: none;
  }
}
