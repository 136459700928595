@import "src/app/theme/variables";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.mainButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-extra-small;

  padding: $spacing-extra-small;
  min-height: $input-height;

  background: transparent;
  border: 1px solid $border-color;
  border-radius: $radius-extra-small;
  color: $title-text-color;
  outline: none;
  text-align: left;

  &:disabled {
    color: $disabled-input-color;
  }

  &:not(:disabled) {
    &:hover {
      border-color: $highlight-color;
    }
    &:focus {
      border-color: $primary-color;
    }
  }

  .chevronOpen {
    transform: rotate(180deg);
  }
}

.placeholder {
  opacity: 0.5;
}

.picker {
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + $spacing-extra-small));
  min-width: 100%; // TODO: should this be `width: 100%`?
  z-index: 1;

  padding: $spacing-extra-small 0;

  background: $container-color;
  border-radius: $radius-extra-small;
  border: 1px solid $border-color;

  @include card-shadow;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: $spacing-extra-small;
}
