@import "src/app/theme/variables";

.button {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: inline;
  color: #{$secondary-color};
}
