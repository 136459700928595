@import "src/app/theme/variables";

.container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;

  z-index: 1000;

  background: $overlay-color;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  overflow: hidden;
  border-radius: $radius-small;
  background: $body-color;
  border: 1px solid $border-color;

  @include media-max($breakpoint-extra-small) {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
