@import "src/app/theme/variables";

.button {
  gap: 5px;
  padding: 10px;
  border-radius: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: $input-height;

  background: transparent;
  border: 1px solid transparent;

  outline: none;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;

  --button-text-color: #{$text-color-on-primary};
  --button-color: #{$primary-color};

  &[data-size='small'] {
    height: auto;
    padding: 8px 10px;
    font-size: $font-size-small;
  }

  &[data-size='tiny'] {
    height: auto;
    padding: 8px 10px;
    font-size: $font-size-extra-small;
  }

  .icon {
    font-size: 1.3em;
  }
}

.button[data-color='secondary'] {
  --button-color: #{$secondary-color};
  --button-text-color: #{$text-on-secondary};
}

.button[data-color='neutral'] {
  --button-color: #{$text-color};
  --button-text-color: #{$accent-color};
}

.button[data-color='delete'] {
  --button-text-color: #{$white};
  --button-color: #{$error-color};
}

.button[data-color='warning'] {
  --button-text-color: #{$accent-color};
  --button-color: #{$warning-color};
}


.button[data-mode='text'] {
  padding-inline: 0;
  color: var(--button-color);
}

.button[data-mode='outlined'] {
  color: var(--button-color);
  border-color: var(--button-color);
}

.button[data-mode='outlined']:hover,
.button[data-mode='contained'] {
  &:not([data-disabled=true]) {
     color: var(--button-text-color);
     background: var(--button-color);
   }
}

.button[data-disabled=true] {
  cursor: no-drop;
  color: $disabled-text;

  &:not([data-mode='text']) {
    background: $disabled-color;
    border-color: $disabled-color;
  }
}
