@import "src/app/theme/variables";

.overlay {
  z-index: 100;
  display: flex;
  position: fixed;

  isolation: revert;

  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: $overlay-color;

  align-items: center;
  justify-content: center;

  padding: $spacing-normal;
}

.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  border-radius: $radius-extra-small;
  background: $container-color;

  max-width: 1000px; // todo: this feels arbitrary?
  max-height: 100%;
  min-height: 400px; // todo: also arbitrary?

  --padding: $spacing-normal;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $spacing-small;
    background: $accent-color;

    .closeIcon {
      font-size: 2em;
      cursor: pointer;
    }
  }

  .bottomBar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: var(--padding);
    padding: 0 var(--padding);
  }

  .body {
    flex: 1;
    word-break: break-all; // todo: is this necessary?
    padding: $spacing-normal;
    overflow: auto;
  }
}
