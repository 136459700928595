@import "src/app/theme/variables";

.root {
  display: flex;
  flex-direction: column;
  gap: $spacing-extra-small;
}

.item {
  border: 1px solid $border-color;
  border-radius: $radius-extra-small;
  overflow: hidden;
}

.header {
  all: unset;

  display: flex;
}

.trigger {
  all: unset;

  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 $spacing-small;
  height: $input-height;

  cursor: pointer;

  &:hover,
  &[data-state='open'] {
    background: $highlight-color;
  }

  &[data-state='open']>.chevron {
    transform: rotate(180deg);
  }
}

.content {
  padding: $spacing-small;
}