@import "src/app/theme/variables";

.picker {
  display: flex;
  flex-direction: column;
}

.item {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  text-align: start;

  padding: $spacing-extra-small calc($spacing-extra-small * 2);

  &:hover {
    background: $highlight-color;
  }
}
