@import "src/app/theme/variables";

.react-datepicker__portal {
  background-color: $overlay-color !important;
}

.react-datepicker {
  padding: $spacing-small;

  color: $text-color;
  border-radius: $radius-normal !important;
  background-color: $body-color !important;
  //border: 1px solid $border-color !important;
  border: none !important;

  @include media-max($breakpoint-small) {
    width: min-content;
  }

  .react-datepicker__navigation-icon {
    font-size: $font-size-small !important;

    ::before {
      width: 6px;
      aspect-ratio: 1/1;
    }
  }

  .react-datepicker__navigation.react-datepicker__navigation--previous {
    top: $spacing-small;
    left: $spacing-small;
  }

  .react-datepicker__navigation.react-datepicker__navigation--next {
    top: $spacing-small;
    right: $spacing-small;
  }

  .react-datepicker__month-container {
    color: inherit;
    background-color: inherit;

    .react-datepicker__header  {
      border-bottom: 0;
      border-radius: $radius-small;
      color: $text-color;
      background-color: $container-color;

      .react-datepicker__current-month {
        color: inherit;
        font-weight: 400;
        font-family: inherit;
        font-size: $font-size-base !important;
      }

      .react-datepicker__day-name {
        font-weight: 600;
        color: $secondary-color;
      }
    }

    &:not(:nth-of-type(3)) .react-datepicker__header {
      @include media-min($breakpoint-small) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &:nth-of-type(3) .react-datepicker__header {
      @include media-min($breakpoint-small) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    .react-datepicker__month {
      margin-top: $spacing-small;

      @include media-max($breakpoint-small) {
        margin-block: $spacing-small;
      }
    }
  }

  .react-datepicker__day {
    color: $text-color;

    &:hover {
      background-color: $highlight-color;
    }

    &.react-datepicker__day--outside-month {
      &:not(:hover):not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end) {
        opacity: 0.1;
      }
    }
  }

  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    font-weight: 600;
    opacity: 1 !important;
    color: $text-color-on-primary;
    background-color: transparentize($primary-color, 0.3) !important;
  }

  .react-datepicker__day--keyboard-selected {
    &:not(.react-datepicker__day--in-range):not(.react-datepicker__day--in-selecting-range):not(.react-datepicker__day--selecting-range-start):not(.react-datepicker__day--selecting-range-end) {
      background-color: transparent !important;
    }
  }

  .react-datepicker__children-container {
    clear: both;
    width: 100%;
  }
}
