@import "src/app/theme/variables";

.container {
  padding: $spacing-extra-small;

  font-size: $font-size-small;
  color: $secondary-text;
  background: $highlight-color;
  border-left: 3px solid transparent;

  &[data-alert-type='info'] {
    border-left: 3px solid transparentize($disabled-color, 0.5);
  }

  &[data-alert-type='warning'] {
    border-left: 3px solid transparentize($secondary-color, 0.5);
  }

  &[data-alert-type='error'] {
    border-left: 3px solid transparentize($error-color, 0.5);
  }

  &[data-alert-type='success'] {
    border-left: 3px solid transparentize($success-color, 0.5);
  }
}
