@import "src/app/theme/variables";

.multiColumnPicker {
  display: flex;
  gap: $spacing-extra-small;
}

.column {
  display: flex;
  flex-direction: column;
  gap: $spacing-extra-small;
}

.item {
  background: none;
  border: none;
  border-radius: $radius-extra-small;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  text-align: start;

  padding: calc($spacing-extra-small / 2) calc($spacing-extra-small);

  &:hover {
    background: $highlight-color;
  }
}
