@import "src/app/theme/variables";

.screen {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: $spacing-medium;
  padding: $spacing-medium;
}

@media screen and (max-width: $breakpoint-small) {
  .screen {
    padding: $spacing-normal;
  }
}
